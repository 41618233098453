@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		@apply font-body;
	}
	:root {
		--logo-scroll-gap: calc(clamp(10rem, 1rem + 20vmin, 30rem) / 14);
		--radius: 0.5rem;
	}
	svg {
		@apply shrink-0;
	}
	h1,
	h2,
	h3,
	h4 {
		@apply font-heading font-semibold;
	}
	ul {
		@apply list-disc;
		li {
			p {
				@apply !m-0;
			}
		}
	}
	summary {
		@apply list-none;
	}
	.background-grid {
		@apply absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#8080802e_1px,transparent_1px),linear-gradient(to_bottom,#8080802e_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)]		
	}
}
@layer utilities {
	@media (prefers-color-scheme: light) {
		.gradient-one {
			background-image: linear-gradient(120deg, #fdfbfb, #ebedee);
		}
	}
}
